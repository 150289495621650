import React, {useEffect} from 'react';
import Sidebar from "../components/Layout/Navigation/Sidebar/Sidebar";
import {Helmet} from "react-helmet";
import Footer from "../components/Layout/Footer";
import Navigation from "../components/Layout/Navigation/Navigation";
import '../../translation';
import {useTranslation} from 'react-i18next';

const ControleDesConnaissances = ({data}) => {
    const {t} = useTranslation('controleDesConnaissances');

    useEffect(() => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, [])
    return (
        <>
            <Navigation/>
            <div className='container-custom page d-flex'>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Contrôle des connaissances | Collège Lycée Lafayette Nice</title>
                    <meta name="description"
                          content="Le Collège Lycée Lafayette offre cursus bilingue et pluridisciplinaire : éducation bi-culturelle, une pédagogie différenciée, la confiance et l'estime de soi."/>
                </Helmet>
                <Sidebar/>
                <div className='content fadeInEarth'>
                    <div className='special-title'>{t('text1')}</div><br/>
                    <ul className='liste'>
                        <li><div className='dot'/> {t('text2')}</li><br/>
                        <li><div className='dot'/> {t('text3')}</li><br/>
                        <li><div className='dot'/> {t('text4')}</li><br/>

                    </ul>
                </div>
            </div>
            <Footer/>
        </>

    )
}

export default ControleDesConnaissances;